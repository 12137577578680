<template>
  <div id="center-key-figures-tabs-html-component">
    {{/* BLOCK NAV TABS */}}
    <nav class="mt-3" v-if="config.selectedCenter">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 1) ? 'active' : ''" v-show="config.showTabs" v-on:click="handleTabIndex(1)">
          <div v-if="html.flux !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-flux">Rendu Visuel de l'export (Flux)</div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 2) ? 'active' : ''" v-show="config.showTabs" v-on:click="handleTabIndex(2)">
          <div v-if="html.flux !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-stats">Rendu Visuel de l'export (CA)</div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 3) ? 'active' : ''" v-show="config.showTabs" v-on:click="handleTabIndex(3)">
          <div v-if="html.flux !== '' && html.sales !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-category">Fréquentations & Chiffres d'affaires</div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
      </div>
    </nav>
    {{/* HTML EXPORT FLUX */}}
    <div class="tab-pane mb-5 fade" :class="(tabs.tabIndex === 1) ? 'show active' : ''" v-show="tabs.tabIndex === 1" v-if="this.config.selectedCenter">
      <b-card class="border-top-0" no-body>
        <div class="text-center mt-2" v-if="html.flux === '' || tabs.isLoadingFlux">
          <div class="mt-5">
            <b-spinner variant="success" label="Spinning"></b-spinner>
            <div class="text-primary">Chargement en cours ...</div>
          </div>
        </div>

        {{/* BUTTON EXPORT XLSX */}}
        <div class="d-flex justify-content-end mt-2 mr-2" v-if="!tabs.isLoadingFlux">
          <button class="btn btn-dark btn-sm" v-on:click="exportXlsx(true)">Export XLSX</button>
        </div>
        <div class="response-api-html-export-flux p-4" v-show="!tabs.isLoadingFlux">
          <div v-html="html.flux">
          </div>
        </div>

        <ApexChart
            :id="'chart-key-figures-flux'"
            :ref="'chartFlux'"
            height="400"
            :options="apexChartFlux.options"
            :series="(chart.fluxSeries) ? chart.fluxSeries : []"
            v-if="!tabs.isLoadingFlux"
            :key="99"
        />
      </b-card>
    </div>
    {{/* HTML EXPORT SALES */}}
    <div class="tab-pane mb-5 fade" :class="(tabs.tabIndex === 2) ? 'show active' : ''" v-show="tabs.tabIndex === 2">
      <b-card class="border-top-0" no-body>
        <div class="text-center mt-2" v-if="html.sales === '' || tabs.isLoadingSales">
          <div class="mt-5">
            <b-spinner variant="success" label="Spinning"></b-spinner>
            <div class="text-primary">Chargement en cours ...</div>
          </div>
        </div>

        {{/* BUTTON EXPORT XLSX */}}
        <div class="d-flex justify-content-end mt-2 mr-2" v-if="!tabs.isLoadingSales">
          <button class="btn btn-dark btn-sm" v-on:click="exportXlsx(false)">Export XLSX</button>
        </div>
        <div class="response-api-html-export-flux p-4" v-show="!tabs.isLoadingSales">
          <div v-html="html.sales">
          </div>
        </div>

        <ApexChart
            :id="'chart-key-figures-sales'"
            :ref="'chartSales'"
            height="400"
            :options="apexChartSales.options"
            :series="(chart.salesSeries) ? chart.salesSeries : []"
            v-if="!tabs.isLoadingSales"
            :key="100"
        />
      </b-card>
    </div>
    {{/* CHART FLUX AND SALES */}}
    <div class="tab-pane mb-5 fade" :class="(tabs.tabIndex === 3) ? 'show active' : ''" v-show="tabs.tabIndex === 3">
      <b-card class="border-top-0 p-4" no-body>
        <div class="text-center mt-2" v-if="tabs.isLoadingChartFluxSales">
          <div class="mt-5">
            <b-spinner variant="success" label="Spinning"></b-spinner>
            <div class="text-primary">Chargement en cours ...</div>
          </div>
        </div>
        <div v-else>
          <h2 class="text-success mt-3 mb-3">
            Fréquentation & Chiffres d'affaires du CC {{ config.displayCenter.data.name }}
          </h2>
          <ApexChart
              :id="'chart-key-figures-sales-flux'"
              :ref="'chartFluxSales'"
              height="400"
              :options="apexChartFluxAndSales.chartOptions"
              :series="(chart.fluxSalesSeries) ? chart.fluxSalesSeries : []"
              v-if="!tabs.isLoadingChartFluxSales"
              :key="101"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    config: Object,
    html: Object,
    tabs: Object,
    chart: Object
  },
  data() {
    return {
      loader: {
        isLoading: false
      },
      apexChartFlux: {
        id: 'chart-key-figures-flux',
        options: {
          chart: {
            type: 'bar',
            toolbar: {
              show: true
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: 'category'
          },
          title: {
            text: 'Fréquentation',
            align: 'left',
            style: {
              color: '#444'
            }
          },
          stroke: {
            width: 1.2
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'right',
            horizontalAlign: 'right',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            itemMargin: {
              vertical: 10
            }
          },
          colors: [],
        },
      },
      apexChartSales: {
        id: 'chart-key-figures-sales',
        options: {
          chart: {
            type: 'bar',
            toolbar: {
              show: true
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: 'category'
          },
          title: {
            text: 'Chiffre d\'affaires',
            align: 'left',
            style: {
              color: '#444'
            }
          },
          stroke: {
            width: 1.2
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'right',
            horizontalAlign: 'right',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            itemMargin: {
              vertical: 10
            }
          },
          colors: ['#00a86c', '#d9d9d9', '#a6a6a6'],
        },
      },
      apexChartFluxAndSales: {
        id: 'chart-key-figures-sales-flux',
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
          },
          stroke: {
            width: 2
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'right',
            horizontalAlign: 'right',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            itemMargin: {
              vertical: 10
            }
          },
          colors: ['#00a86c', '#d9d9d9', '#a6a6a6'],
          xaxis: {
            type: 'category'
          },
          yaxis: [{
            opposite: true,
            title: {
              text: 'Flux'
            },
            max: function(max) {return (Math.round((max * 1.2) /10)) * 10},
            forceNiceScale: true
          },

            {show: false},
            {show: false},
            {show: false},
            {show: false},
            {
              opposite: false,
              title: {
                text: 'CA'
              },
              max: function(max) {return (Math.round((max * 1.2) /10)) * 10},
              forceNiceScale: true
            }]
        },
      },
    }
  },
  mounted() {
    this.apexChartFlux.options.colors = this.handleColors()
  },
  created() {
      this.apexChartFlux.options.title.text += ' ' + this.config.displayCenter.data.name
      this.apexChartSales.options.title.text += ' ' + this.config.displayCenter.data.name
  },
  methods: {
    /*----------------- HANDLE TAB INDEX ------------------*/
    handleTabIndex(index) {
      this.$emit('emit-tab-index', index)
    },
    /*----------------- EXPORT XLSX ------------------*/
    exportXlsx(isFlux) {
      this.$emit('emit-export-xlsx', isFlux)
    },
    handleColors() {
      if (this.config.displayCenter.title === 'Béziers') {
        return ['#00a86c', '#0ABFB3', '#d9d9d9', '#E6CFE6',  '#a6a6a6', '#B3A6A1']
      } else {
        return ['#00a86c','#d9d9d9', '#a6a6a6']
      }
    }
  },
  components: {
    ApexChart: () => import('vue-apexcharts'),
  }
}
</script>
